import { Stack, Typography } from '@mui/material';
import React, { createRef, useMemo, useRef, useState } from 'react';
import { theme } from 'theme';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { MessageFrom } from 'pages/user/components/MessageFrom/MessageFrom';
import { MessageTo } from 'pages/user/components/MessageTo/MessageTo';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  ConversationPartModel,
  ConversationPartTypeEnum,
  getConverstationAsync,
  handleTag,
  TagAiPartModel,
} from 'store/intercomSlice';
import { HeaderPage } from 'components/HeaderPage/HeaderPage';
import { DASHBOARD_ROUTES } from 'Routes';
import { UserInfo } from '@data/models/user.model';
import { useSelector } from 'react-redux';
import { selectUser } from 'store/userSlide';
import { selectNavigateLinkHeader } from 'store/navigateSlide';
import { selectCompany } from 'store/companySlide';
import { CompanyInfo } from '@data/models/company.model';

export const TabTexts: React.FC = () => {
  const dispatch = useAppDispatch();
  const { user_id } = useParams();
  const conversation = useAppSelector((state) => state.intercom.converstation);
  const messagesEndRef = useRef<any>(null);
  const tagRef = useRef<any>(null);
  const ref = useRef<any>(null);

  const loading = useAppSelector((state) => state.intercom.loading);
  const tagCurrent = useAppSelector((state) => state.intercom.tag);
  const [top, setTop] = React.useState(0);
  const [windowSize, setWindowSize] = React.useState(100);
  const userInfo: UserInfo = useSelector(selectUser);
  const [conversationPartId, setConversationPartId] = React.useState<any>();
  const navigateLinkHeader = useSelector(selectNavigateLinkHeader);
  const companyInfo: CompanyInfo = useSelector(selectCompany);
  const [isClick, setIsClick] = React.useState<boolean>(false);
  const [indexRef, setIndex] = React.useState<number>(0);

  const [heightSpacer, setHeightSpacer] = useState<number>(0);

  const findConversationPartId = () => {
    const conversationPartIds: any =
      conversation?.conversationPart &&
      conversation?.conversationPart.map((con: ConversationPartModel) => {
        if (
          con.tags &&
          con.tags.length > 0 &&
          con.conversationAuthorType === 'user'
        ) {
          for (const tagItem of con.tags) {
            if (tagItem === tagCurrent) {
              return con.conversationPartId;
            }
          }
        }
      });
    if (conversationPartIds && conversationPartIds?.length > 0) {
      const newArray = conversationPartIds?.filter(
        (value: any) => value !== undefined,
      );
      if (!conversationPartId) {
        setConversationPartId(newArray);
      } else {
        if (
          newArray &&
          conversationPartId.toString() !== newArray?.toString()
        ) {
          setConversationPartId(
            conversationPartIds &&
              conversationPartIds?.length > 0 &&
              conversationPartIds?.filter((value: any) => value !== undefined),
          );
        }
      }
    }
  };

  const handleClickOutSide = (event: MouseEvent) => {
    if (
      tagRefs[tagCurrent]?.current &&
      !tagRefs[tagCurrent]?.current?.contains(event.target as Node)
    ) {
      setConversationPartId(null);
      setIndex(0);
      dispatch(handleTag(''));
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  React.useEffect(() => {
    if (user_id) {
      dispatch(getConverstationAsync(user_id));
    }
  }, []);

  React.useEffect(() => {
    if (tagCurrent) {
      findConversationPartId();
      setIndex(0);
    }
  }, [tagCurrent, conversation, isClick]);

  React.useEffect(() => {
    setTop(tagRef.current.scrollHeight + 220);
    if (conversation?.conversationPart && !tagCurrent) {
      setTimeout(() => {
        scrollToBottom();
      }, 800);
    }
  }, [conversation, windowSize]);

  React.useEffect(() => {
    setTop(tagRef.current.scrollHeight + 220);
    if (tagCurrent && conversationPartId?.length > 0) {
      if (
        conversation?.conversationPart &&
        conversation?.conversationPart.length > 0
      ) {
        const item = conversation.conversationPart.find(
          (item) => item.conversationPartId === conversationPartId[indexRef],
        );
        if (item) {
          const index = conversation?.conversationPart?.indexOf(
            item || undefined,
          );
          let total = 0;
          for (let i = index; i < conversation.conversationPart?.length; i++) {
            const height =
              messageRefs[conversation?.conversationPart[i]?.conversationPartId]
                ?.current?.clientHeight || 0;

            total = total + height;
          }
          const heightItem =
            messageRefs[
              conversation?.conversationPart[indexRef]?.conversationPartId
            ]?.current?.clientHeight || 0;
          const resuilt =
            (ref?.current?.clientHeight - total - heightItem) / 6.5;
          setTimeout(() => {
            setHeightSpacer(resuilt);
            messageRefs?.[
              conversationPartId?.[indexRef]
            ]?.current?.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
            });
            testRefs?.[conversationPartId?.[indexRef]]?.current?.scrollIntoView(
              {
                behavior: 'smooth',
                block: 'start',
              },
            );
          }, 800);
        }
      }
    }
  }, [indexRef, conversationPartId, conversation]);

  const messageRefs = useMemo(() => {
    const refs: { [key: string]: React.RefObject<HTMLDivElement> } = {};
    conversation?.conversationPart?.forEach((msg) => {
      refs[msg.conversationPartId] = createRef<HTMLDivElement>();
    });
    return refs;
  }, [conversation]);

  const tagRefs = useMemo(() => {
    const refs: { [key: string]: React.RefObject<HTMLDivElement> } = {};
    conversation?.tagAiPart?.forEach((msg) => {
      refs[msg?.tagName || ''] = createRef<HTMLDivElement>();
    });
    return refs;
  }, [conversation]);

  const testRefs = useMemo(() => {
    const refs: { [key: string]: React.RefObject<HTMLDivElement> } = {};
    conversation?.conversationPart?.forEach((msg) => {
      refs[msg.conversationPartId || ''] = createRef<HTMLDivElement>();
    });
    return refs;
  }, [conversation]);

  React.useEffect(() => {
    if (conversationPartId) {
      if (indexRef < conversationPartId.length - 1) {
        setIndex(indexRef + 1);
      } else {
        setIndex(0);
      }
    }
  }, [isClick]);

  React.useEffect(() => {
    function handleResize() {
      setWindowSize(Math.round((window.outerWidth / window.innerWidth) * 100));
    }

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  React.useEffect(() => {
    if (
      navigateLinkHeader.fromLink !==
        DASHBOARD_ROUTES.COMPANY_ID_EMPLOYEES(companyInfo.companyId) &&
      navigateLinkHeader.fromLink !== DASHBOARD_ROUTES.ROUTE_USER_LIST
    ) {
      dispatch(handleTag(''));
    }
    function handleResize() {
      dispatch(handleTag(''));
    }
    window.addEventListener('beforeunload', handleResize);

    return () => window.removeEventListener('beforeunload', handleResize);
  }, []);

  React.useEffect(() => {
    window.addEventListener('click', handleClickOutSide, true);
    return () => {
      window.removeEventListener('click', handleClickOutSide, true);
    };
  }, [tagCurrent, tagRefs]);

  return (
    <>
      <HeaderPage
        title={{
          title:
            userInfo?.firstName || userInfo?.lastName
              ? userInfo?.firstName + ' ' + userInfo?.lastName
              : '',
          hasBack: true,
        }}
        tabs={[
          {
            title: 'Overview',
            selected: false,
            link: DASHBOARD_ROUTES.USER_DETAIL_ID(userInfo.userId),
          },
          {
            title: 'Usage Logs',
            selected: false,
            link: DASHBOARD_ROUTES.USER_TRACKING(userInfo.userId),
          },
          {
            title: 'User Notes',
            selected: false,
            link: DASHBOARD_ROUTES.USER_ID_NOTES(userInfo.userId),
          },
          {
            title: 'User Events',
            selected: false,
            link: DASHBOARD_ROUTES.USER_ID_LOSS_EVENTS(userInfo.userId),
          },
          {
            title: 'Check-ins',
            selected: false,
            link: DASHBOARD_ROUTES.USER_ID_CHECK_INS(userInfo.userId),
          },
          {
            title: 'Conversations',
            selected: true,
            link: DASHBOARD_ROUTES.USER_ID_CONVERSATION(userInfo.userId),
          },
        ]}
      />
      <Stack
        sx={{
          [theme.breakpoints.down('md')]: {
            width: '100%',
          },
          [theme.breakpoints.up(768)]: {
            width: '70%',
          },
          height: '100%',
          marginTop: '24px',
          marginLeft: '120px',
        }}
      >
        <Stack
          sx={{
            color: '#7D818C',
            fontWeight: 700,
            fontSize: '13px',
          }}
          flexDirection={'row'}
          width={'100%'}
          alignItems={'center'}
          ref={tagRef}
          minHeight={'50px'}
          gap={1}
        >
          Tags:
          {conversation?.tagAiPart && (
            <Stack
              gap={'12px'}
              flexDirection={'row'}
              sx={{
                flexWrap: 'wrap',
              }}
            >
              {conversation?.tagAiPart.map((tag: TagAiPartModel) => {
                return (
                  <>
                    <Stack
                      sx={{
                        position: 'relative',
                        ':hover': {
                          backgroundColor: 'rgba(53, 103, 102, 0.25)',
                          borderRadius: '20px',
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          color:
                            tagCurrent === tag.tagName
                              ? 'rgba(33, 59, 84, 1)'
                              : '#356766',
                          backgroundColor: '#3567661A',
                          border:
                            tagCurrent === tag.tagName
                              ? '2px solid rgba(33, 59, 84, 1)'
                              : '1px solid #356766',
                          borderRadius: '20px',
                          paddingX: '8px',
                          height: '24px',
                          fontSize: '14px',
                          fontWeight: 500,
                          width: 'fit-content',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          dispatch(handleTag(tag.tagName));
                          setIsClick(!isClick);
                        }}
                        component="span"
                        ref={tagRefs[tag?.tagName || '']}
                      >
                        {tag.tagName}
                      </Typography>
                      <Typography
                        sx={{
                          position: 'absolute',
                          right: -8,
                          bottom: -4,
                          borderRadius: 100,
                          backgroundColor:
                            tagCurrent === tag.tagName
                              ? 'rgba(33, 59, 84, 1)'
                              : 'rgba(53, 103, 102, 1)',
                          height: '16px',
                          width: tagCurrent === tag.tagName ? 'auto' : '16px',
                          paddingX: '4px',
                          textAlign: 'center',
                          fontSize: '11px',
                          fontWeight: 700,
                          color: 'rgba(255, 255, 255, 1)',
                        }}
                      >
                        {tagCurrent === tag.tagName
                          ? `${indexRef + 1} of ${tag.total}`
                          : tag.total}
                      </Typography>
                    </Stack>
                  </>
                );
              })}
            </Stack>
          )}
        </Stack>

        <Stack
          spacing={2}
          display={'flex'}
          width={tagRef?.current?.scrollWidth}
          overflow={'auto'}
          paddingBottom={heightSpacer}
          bgcolor={conversation?.conversationPart ? '#F7F7F7' : 'transparent'}
          borderTop={'1px solid #9999997A'}
          sx={{
            [theme.breakpoints.down(420)]: {
              height: '500px',
              top: top + 80,
              position: 'absolute',
            },
            [theme.breakpoints.up(420)]: {
              height: '500px',
              top: top + 24,
              position: 'absolute',
            },
            [theme.breakpoints.up(440)]: {
              top: top,
              position: 'fixed',
              bottom: 0,
              height: 'auto',
            },
          }}
          ref={ref}
        >
          {!loading && conversation?.conversationPart && (
            <>
              {conversation?.conversationPart.map(
                (item: ConversationPartModel) => {
                  if (
                    item?.conversationAuthorType ===
                      ConversationPartTypeEnum.admin ||
                    item?.conversationAuthorType ===
                      ConversationPartTypeEnum.bot
                  ) {
                    return (
                      <>
                        <div
                          key={item.conversationPartId}
                          ref={messageRefs[item.conversationPartId]}
                        >
                          <MessageTo
                            body={item?.body}
                            time={moment
                              .unix(item?.createdAt || 0)
                              .format('MM/DD/YYYY hh:mm A')}
                            avatar={item?.avatar}
                            authorName={item?.authorName || ''}
                          />
                        </div>
                      </>
                    );
                  } else {
                    return (
                      <>
                        <div
                          key={item.conversationPartId}
                          ref={testRefs[item.conversationPartId]}
                        />
                        <div
                          key={item.conversationPartId}
                          ref={messageRefs[item.conversationPartId]}
                        >
                          <MessageFrom
                            body={item?.body}
                            time={moment
                              .unix(item?.createdAt || 0)
                              .format('MM/DD/YYYY hh:mm A')}
                            avatar={item?.avatar || ''}
                            authorName={item?.authorName || ''}
                            tags={item.tags}
                            conversationId={item.conversationPartId}
                            conversationPartIdRef={
                              conversationPartId?.[indexRef]
                            }
                            tagCurrent={tagCurrent}
                          />
                        </div>
                      </>
                    );
                  }
                },
              )}
            </>
          )}
          {!tagCurrent && <div ref={messagesEndRef} />}
        </Stack>
      </Stack>
    </>
  );
};
