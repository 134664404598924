import React, { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import Button from 'components/Button';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ModalCustom from 'components/Modal';
import Select from 'components/Select';
import { InactiveReasonsModel } from '@data/models/inactive-reasons';
import { getInactiveReasons, updateAdminUser } from 'data/api/user.api';
import { UserInfo } from '@data/models/user.model';
import { DASHBOARD_ROUTES } from 'Routes';
import { updateUserNote } from 'data/api/user-note.api';
import { NoteUserUpdate } from '@data/models/user-note.model';
import RichTextEditorField from 'components/RichTextEditorField';

interface Props {
  openModalFeeling: boolean;
  setOpenModalFeeling: any;
  setUserNoteUpdate?: any;
  userData: UserInfo | undefined;
  userNoteData?: NoteUserUpdate | undefined;
}

export interface InactiveReasonForm {
  inactiveReason: string;
  inactiveNotes?: string;
}
const ModalConfirmActive: FC<Props> = ({
  openModalFeeling,
  setOpenModalFeeling,
  userData,
  userNoteData,
  setUserNoteUpdate,
}) => {
  const [inactiveReasons, setInactiveReasons] =
    React.useState<InactiveReasonsModel[]>();
  const { user_id } = useParams();
  const navigate = useNavigate();

  const { control, reset, clearErrors, handleSubmit } =
    useForm<InactiveReasonForm>({
      reValidateMode: 'onChange',
      mode: 'onChange',
    });

  const resetForm = () => {
    reset({
      inactiveReason: userNoteData?.reasonId || '',
      inactiveNotes: userNoteData?.note || '',
    });
    clearErrors();
  };

  const handleClose = () => {
    setOpenModalFeeling(false);
    setUserNoteUpdate(null);
  };

  const fetchInactiveReasons = async () => {
    try {
      if (openModalFeeling) {
        const data = await getInactiveReasons();
        setInactiveReasons(data.inactiveReasons);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCreateUserNotes = async (values: InactiveReasonForm) => {
    try {
      if (user_id) {
        if (userData) {
          await updateAdminUser(user_id, {
            ...userData,
            reason: values.inactiveNotes,
            reasonId: values.inactiveReason,
          })
            .then(() => {
              toast.success('Update user successfully!');
              navigate(DASHBOARD_ROUTES.ROUTE_USER_LIST);
            })
            .catch((error) => {
              toast.error(error);
              return;
            });
        }
      }
      if (userNoteData) {
        await updateUserNote({
          note: values.inactiveNotes,
          reasonId: values.inactiveReason,
          userNoteId: userNoteData.userNoteId,
        })
          .then(() => {
            toast.success('Update user note successfully!');
            window.location.reload();
          })
          .catch((error) => {
            toast.error(error);
            return;
          });
      }
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    fetchInactiveReasons();
    resetForm();
  }, [userNoteData, userData]);

  return (
    <>
      <ModalCustom
        open={openModalFeeling}
        handleClose={handleClose}
        groupButton={
          <form onSubmit={handleSubmit(handleCreateUserNotes)} noValidate>
            <Stack
              sx={{
                height: '520px',
                width: '740px',
                paddingX: '32px',
                marginBottom: '24px',
                justifyContent: 'space-between',
              }}
            >
              <Stack spacing={2}>
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: '28px',
                  }}
                >
                  Inactive Member
                </Typography>
                <Stack>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: '15px',
                    }}
                  >
                    Marking this member as inactive lets us know to stop
                    communication.
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: '15px',
                    }}
                  >
                    Tell us why — it helps keep our records clear and everyone
                    in the loop!
                  </Typography>
                </Stack>
                <Select
                  name="inactiveReason"
                  label="Inactive Reason"
                  control={control}
                  variant="outlined"
                  placeholder="Choose the inactive reason"
                  rules={{ required: 'Inactive Reason is required.' }}
                  required
                  data={
                    inactiveReasons && inactiveReasons.length > 0
                      ? inactiveReasons?.map((item: InactiveReasonsModel) => {
                          return {
                            value: item?.inactiveReasonId || '',
                            label: item?.inactiveReasonName || '',
                          };
                        })
                      : []
                  }
                  showSelected={true}
                />
                <RichTextEditorField
                  name="inactiveNotes"
                  label="Inactive Notes"
                  control={control}
                  placeholder="Add a note"
                  editorStyle={{
                    border: '1px solid #79747E',
                    borderRadius: '4px',
                    paddingRight: '12px',
                    paddingLeft: '12px',
                  }}
                  wrapperClassName="custom-editor"
                />
              </Stack>

              <Stack
                direction={'row'}
                sx={{
                  width: '100%',
                  justifyContent: 'end',
                }}
                spacing={'12px'}
              >
                <Button
                  onClick={handleClose}
                  sx={{
                    fontWeight: 700,
                    fontSize: '14px',
                    lineHeight: '20px',
                    width: '97px',
                    height: '40px',
                    border: 'none',
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  sx={{
                    fontWeight: 700,
                    fontSize: '14px',
                    width: '97px',
                    height: '40px',
                    lineHeight: '20px',
                  }}
                >
                  Save
                </Button>
              </Stack>
            </Stack>
          </form>
        }
        title={''}
        subTittle={''}
        PaperProps={{
          sx: {
            width: '100%',
            borderRadius: 7,
            padding: 3,
            maxWidth: 'fit-content !important',
          },
        }}
      />
    </>
  );
};

export default ModalConfirmActive;
