import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { Stack, Typography, useTheme } from '@mui/material';
import {
  GridColDef,
  GridEventListener,
  GridRenderCellParams,
} from '@mui/x-data-grid';
import Table from 'components/Table';
import { saveUser } from 'store/userSlide';
import { SearchTracking } from 'components/SearchTracking';
import { useForm } from 'react-hook-form';
import MultiSelectTracking from 'components/MultiSelectTracking';
import DatePickerTracking from 'components/DatePickerTracking';
import CheckIcon from 'assets/icon-components/checkIcon';
import { User, UsersPagination } from '@data/models/user.model';
import { HeaderPage } from 'components/HeaderPage/HeaderPage';
import { DASHBOARD_ROUTES } from 'Routes';
import { IListRequest } from '@data/models/common.model';
import { getAllUsers } from 'data/api/company-employee.api';
import { getAllCompanies } from 'data/api/company.api';
import { CompaniesPagination, Company } from '@data/models/company.model';
import { saveNavigateLinkHeader } from 'store/navigateSlide';
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import { getConversationTag } from 'data/api/user.api';
import { handleTag } from 'store/intercomSlice';

const FilterBox = ({ control, label, name, data, fullWidth }: any) => {
  return (
    <MultiSelectTracking
      name={name}
      label={label}
      control={control}
      data={data}
      size="small"
      fullWidth={fullWidth}
      variant="outlined"
      customSx={{
        form: { height: '32px !important' },
        label: {
          fontWeight: 'bold',
          fontSize: '14px',
          lineHeight: '20px',
        },
        select: {
          border: '1px solid #213B54',
          borderRadius: '10px',
          color: '#213B54',
        },
      }}
    />
  );
};

export const UserListNew = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const [companies, setCompanies] = React.useState<Company[]>([]);
  const [tag, setTag] = React.useState<any[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [query, setQuery] = React.useState<string>();
  const [pager, setPager] = useState<any>();
  const [filter, setFilter] = useState<IListRequest>({
    order: undefined,
    page: 0,
    pageSize: 50,
    sortBy: 'created_date',
  });

  const { handleSubmit, control, watch } = useForm({
    mode: 'onChange',
  });

  const fetchCompaniesList = async () => {
    try {
      const data: CompaniesPagination = await getAllCompanies();
      setCompanies(data.companies);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchUsersList = async (values?: any) => {
    try {
      const request = {
        isActive: values.isActive,
        isAdmin: values.isAdmin,
        startDate:
          values?.startDate && moment(values?.startDate).isValid()
            ? moment(values?.startDate).startOf('day').toString()
            : undefined,
        endDate:
          values?.endDate && moment(values?.endDate).isValid()
            ? moment(values?.endDate).endOf('day').toString()
            : undefined,
        companies: values.companies,
        tags: values.tags,
        query: query,
      };

      const response: UsersPagination = await getAllUsers({
        ...filter,
        ...request,
      });

      setUsers(response.users);
      setPager(response.pager);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchConversationTag = async () => {
    try {
      const data: any = await getConversationTag();
      setTag(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleRowClick: GridEventListener<'rowClick'> = (params) => {
    dispatch(saveUser(params.row));
    const fromLink = DASHBOARD_ROUTES.ROUTE_USER_LIST;
    const toLink = DASHBOARD_ROUTES.ROUTE_USER_DETAIL + params.row.userId;
    dispatch(saveNavigateLinkHeader({ fromLink, toLink }));
    navigate(toLink);
  };

  React.useEffect(() => {
    handleSubmit(fetchUsersList)();
  }, [filter, query]);

  React.useEffect(() => {
    fetchCompaniesList();
    fetchConversationTag();
  }, []);

  React.useEffect(() => {
    const subscription = watch(
      async () => await handleSubmit(fetchUsersList)(),
    );
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch, query]);

  const newColumns: GridColDef[] = [
    {
      field: 'companyName',
      headerName: 'Company',
      flex: 1,
      minWidth: 200,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Typography
            sx={{
              fontSize: '12px',
            }}
          >
            {params.row.companyName}
          </Typography>
        );
      },
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      minWidth: 200,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Typography
            sx={{
              fontSize: '12px',
            }}
          >
            {params.row.name}
          </Typography>
        );
      },
    },
    {
      field: 'currentEvent',
      headerName: 'Current Event',
      flex: 1,
      minWidth: 240,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Typography
            sx={{
              fontSize: '12px',
            }}
          >
            {params.row?.currentEvent || ''}
          </Typography>
        );
      },
    },
    {
      field: 'conversationContactsIntercomId',
      headerName: 'Link to Intercom',
      flex: 1,
      sortable: true,
      minWidth: 150,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <>
            {params.row.conversationContactsIntercomId && (
              <Link
                target="_blank"
                rel="noopener noreferrer"
                onClick={(event) => event.stopPropagation()}
                to={`${process.env.REACT_APP_LINK_TO_INTERCOM_URL}${params.row?.conversationContactsIntercomId}/all-conversations`}
                style={{
                  textDecorationColor: '#213B54',
                }}
              >
                <Stack
                  direction={'row'}
                  gap={0.5}
                  sx={{
                    color: '#213B54',
                  }}
                >
                  Open
                  <OpenInNewRoundedIcon
                    sx={{
                      height: '16px',
                      width: '16px',
                    }}
                  />
                </Stack>
              </Link>
            )}
          </>
        );
      },
    },
    {
      field: 'tags',
      headerName: 'Tags',
      flex: 1,
      minWidth: 280,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Stack width={'100%'} flexWrap={'wrap'} direction={'row'}>
            {params.row.tags &&
              params.row.tags.length > 0 &&
              params.row.tags.map((item: any) => {
                return (
                  <div
                    key={item}
                    className="!my-[4px]"
                    style={{
                      marginRight: '4px',
                    }}
                    onClick={(event) => event.stopPropagation()}
                  >
                    <Typography
                      sx={{
                        color: '#356766',
                        backgroundColor: '#3567661A',
                        border: '1px solid #356766',
                        borderRadius: '20px',
                        paddingX: '8px',
                        height: '24px',
                        fontSize: '14px',
                        fontWeight: 500,
                        width: 'fit-content',
                        display: 'block',
                      }}
                      onClick={() => {
                        dispatch(saveUser(params.row));
                        const fromLink = DASHBOARD_ROUTES.ROUTE_USER_LIST;
                        const toLink = DASHBOARD_ROUTES.USER_ID_CONVERSATION(
                          params.row.userId,
                        );
                        dispatch(saveNavigateLinkHeader({ fromLink, toLink }));
                        dispatch(handleTag(item));
                        navigate(toLink);
                      }}
                      component="span"
                    >
                      {item}
                    </Typography>
                  </div>
                );
              })}
          </Stack>
        );
      },
    },
    {
      field: 'created_date',
      headerName: 'Date Added',
      flex: 1,
      minWidth: 160,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        return moment
          .utc(params.row.createdDate)
          .local()
          .format('MM/DD/YYYY, hh:mm A');
      },
    },
    {
      field: 'lastLoginDate',
      headerName: 'Last Login',
      flex: 1,
      minWidth: 160,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        return params.row.lastLoginDate
          ? moment
              .utc(params.row.lastLoginDate)
              .local()
              .format('MM/DD/YYYY, hh:mm A')
          : '';
      },
    },
    {
      field: 'loginCount',
      headerName: 'Total Logins',
      flex: 1,
      minWidth: 100,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Typography
            sx={{
              fontSize: '12px',
            }}
          >
            {params.row.loginCount}
          </Typography>
        );
      },
    },
    {
      field: 'is_active',
      headerName: 'Active',
      align: 'center',
      headerAlign: 'center',
      sortable: true,
      flex: 1,
      minWidth: 100,
      renderCell: (params: GridRenderCellParams) => {
        return <>{params.row.active && <CheckIcon />}</>;
      },
    },
    {
      field: 'is_admin',
      headerName: 'Admin',
      align: 'center',
      headerAlign: 'center',
      sortable: true,
      flex: 1,
      minWidth: 100,
      renderCell: (params: GridRenderCellParams) => {
        return <>{params.row.isAdmin && <CheckIcon />}</>;
      },
    },
  ];

  return (
    <>
      <HeaderPage
        title={{ title: 'Users' }}
        tabs={[
          {
            title: 'All Users',
            selected: true,
            link: DASHBOARD_ROUTES.ROUTE_USER_LIST,
          },
          {
            title: 'Add a User',
            selected: false,
            link: DASHBOARD_ROUTES.ROUTE_USER_ADD,
          },
        ]}
      />
      <div className="nv-container">
        <div className="section employees mb-6">
          <Stack sx={{ marginTop: '10px' }} spacing={2} width={'100%'}>
            <Stack
              sx={{
                overflowX: 'auto',
                overflowY: 'hidden',
                [theme.breakpoints.up(1480)]: {
                  flexDirection: 'row',
                  alignItems: 'center',
                },
                [theme.breakpoints.down(1480)]: {
                  flexDirection: 'column',
                  alignItems: 'start',
                },
              }}
              paddingBottom={'8px'}
              width={'100%'}
            >
              <Stack
                direction={{ xs: 'column', xxl: 'row' }}
                spacing={2}
                alignItems={{ xs: 'start', xxl: 'center' }}
                width={'100%'}
              >
                <SearchTracking
                  label="Search for a user"
                  onChange={(title: string) => {
                    console.log('title', title);
                    setQuery(title);
                  }}
                  debounce={800}
                />
                <Stack spacing={2} direction={{ xs: 'column', xl: 'row' }}>
                  <Stack direction={'row'} spacing={2}>
                    <FilterBox
                      name="companies"
                      label="Company"
                      control={control}
                      data={
                        companies
                          ? companies.map((item: Company) => {
                              return {
                                value: item.name,
                                label: item.name,
                              };
                            })
                          : []
                      }
                    />
                    <FilterBox
                      name="isActive"
                      label="Is Active"
                      control={control}
                      data={[
                        {
                          value: 'Yes',
                          label: 'Yes',
                        },
                        {
                          value: 'No',
                          label: 'No',
                        },
                      ]}
                    />
                    <FilterBox
                      name="isAdmin"
                      label="Is Admin"
                      control={control}
                      data={[
                        {
                          value: 'Yes',
                          label: 'Yes',
                        },
                        {
                          value: 'No',
                          label: 'No',
                        },
                      ]}
                    />
                    <FilterBox
                      name="tags"
                      label="Tags"
                      control={control}
                      data={tag.map((item) => {
                        return {
                          value: item.name,
                          label: item.name,
                        };
                      })}
                    />
                  </Stack>
                  <Stack direction={'row'} spacing={2}>
                    <DatePickerTracking
                      name="startDate"
                      label="FROM Last Login Date"
                      control={control}
                      inputProps="!text-sm !h-[32px] !rounded-[10px]"
                    />
                    <DatePickerTracking
                      name="endDate"
                      label="TO Last Login Date"
                      control={control}
                      inputProps="!text-sm !h-[32px] !rounded-[10px]"
                    />
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            <Typography
              sx={{
                fontSize: '15px',
                lineHeight: '24px',
                fontWeight: 500,
                letterSpacing: '0.5px',
              }}
            >
              {pager ? pager?.total : 0} records
            </Typography>
            <Table
              getRowId={(r) => r.userId}
              getRowHeight={() => 'auto'}
              loading={status === 'loading'}
              rows={users && users ? users : []}
              columns={newColumns}
              paginationMode="server"
              sortingMode="server"
              disableColumnFilter={true}
              disableColumnMenu={true}
              filter={filter}
              rowCount={pager?.total || 0}
              onFilterChange={setFilter}
              pageSizeOptions={[10, 25, 50, 100]}
              onRowClick={handleRowClick}
              sx={{
                '& .MuiDataGrid-columnHeaders': {
                  outline: 'none !important',
                  backgroundColor: '#F8F9FB',
                  maxHeight: '47px !important',
                  minHeight: '47px !important',
                  borderWidth: '1px 0px 1px 0px',
                  borderStyle: 'solid',
                  borderColor: '#DBD8D8',
                },
                '& .MuiDataGrid-columnHeaderTitle': {
                  color: '#213B54',
                  fontWeight: 700,
                  fontSize: '13px',
                  lineHeight: '20px',
                  letterSpacing: '0.1px',
                },
                '& .MuiDataGrid-cell': {
                  color: '#000000',
                  fontWeight: 500,
                  fontSize: '12px !important',
                  cursor: 'pointer',
                  padding: '8px !important',
                },
                '& .MuiTablePagination-select': {
                  fontWeight: 500,
                },
                '& .MuiTablePagination-selectLabel': {
                  fontWeight: 500,
                },
                '& .MuiTablePagination-displayedRows': {
                  fontWeight: 500,
                },
                '& .MuiDataGrid-virtualScroller': {
                  overflowY: 'hidden',
                },
              }}
            />
          </Stack>
        </div>
      </div>
    </>
  );
};
