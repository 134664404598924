/* eslint-disable @typescript-eslint/no-empty-function */
import Button from 'components/Button';
import ModalCustom from 'components/ModalCustom';
import ErrorIcon from '@mui/icons-material/Error';
import { Stack, Typography } from '@mui/material';
import React from 'react';

interface ErrorModalProps {
  open: boolean;
  handleClose: () => void;
}

const ExistPhoneModal = ({ open, handleClose = () => {} }: ErrorModalProps) => {
  return (
    <ModalCustom
      modal={open}
      handleClose={handleClose}
      title={
        <Stack direction={'column'} alignItems={'center'} width={'419px'}>
          <ErrorIcon />
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: '21px',
              marginTop: '16px',
              lineHeight: '26.64px',
              color: '#213B54',
              textAlign: 'center',
            }}
          >
            This phone number is already associated with an existing member.
          </Typography>
        </Stack>
      }
      content={
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '20px',
            color: '#213B54',
            paddingX: '24px',
            textAlign: 'center',
          }}
        >
          Please check for duplicates or use a different number.
        </Typography>
      }
      groupButton={
        <Button
          onClick={handleClose}
          variant="contained"
          sx={{
            fontWeight: 700,
            fontSize: '14px',
            lineHeight: '20px',
            width: '24px',
            height: '24px',
            color: '#ffffff',
            marginRight: '24px',
            marginBottom: '24px',
          }}
        >
          OK
        </Button>
      }
    />
  );
};

export default ExistPhoneModal;
