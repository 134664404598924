import { Box, FormControl, FormHelperText, InputLabel } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import WYSIWYGEditor from './WYSIWYGEditor';

type TRichTextFieldProps = {
  control?: any;
  rules?: { [key: string]: string };
  name?: `${string}` | `${string}.${string}` | `${string}.${number}`;
  labelClassName?: string;
  placeholder?: string;
  error?: boolean;
  helperText?: React.ReactNode;
  showSelected?: boolean;
  label: string;
  required?: boolean;
  defaultValue?: string;
  onChange?: any;
  value?: string;
  ref?: any;
  editorStyle?: any;
  wrapperClassName?: any;
};

const CustomRichTextField = ({
  label,
  error = false,
  helperText = '',
  ...props
}: TRichTextFieldProps) => {
  return (
    <FormControl fullWidth error={error}>
      <InputLabel
        shrink={true}
        required={props.required}
        sx={{
          '& .MuiFormLabel-asterisk': {
            color: 'red',
          },
        }}
      >
        {label}
      </InputLabel>
      <Box marginTop={'5px'}>
        <WYSIWYGEditor
          onChange={props.onChange}
          value={props.value}
          placeholder={props.placeholder}
          editorStyle={props.editorStyle}
          wrapperClassName={props.wrapperClassName}
        />
      </Box>
    </FormControl>
  );
};
const RichTextEditorField = ({
  control,
  name,
  rules = {},
  ...props
}: TRichTextFieldProps) => {
  if (control && name && rules) {
    return (
      <Controller
        control={control}
        name={name}
        rules={rules}
        defaultValue={props.defaultValue || ''}
        render={({ field: { ...restField } }) => {
          return <CustomRichTextField {...restField} {...props} />;
        }}
      />
    );
  }
  return <CustomRichTextField {...props} />;
};

export default RichTextEditorField;
