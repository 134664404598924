/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode } from 'react';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
} from '@mui/material';
import { Controller } from 'react-hook-form';

export interface ISelectOption {
  value: any;
  label: string;
}

type TSelectProps = MuiSelectProps & {
  data: ISelectOption[];
  control?: any;
  rules?: { [key: string]: string };
  name?: `${string}` | `${string}.${string}` | `${string}.${number}`;
  labelClassName?: string;
  placeholder?: string;
  error?: boolean;
  helperText?: ReactNode;
  showSelected?: boolean;
};

const CustomSelect = ({
  data,
  label,
  labelClassName = '!bg-white !px-2 !-ml-2',
  error = false,
  helperText = '',
  value = '',
  showSelected,
  placeholder,
  ...props
}: TSelectProps) => {
  return (
    <FormControl fullWidth error={error}>
      <InputLabel
        shrink={true}
        className={labelClassName}
        required={props.required}
        sx={{
          '& .MuiFormLabel-asterisk': {
            color: 'red',
          },
        }}
      >
        {label}
      </InputLabel>
      <MuiSelect
        displayEmpty
        className="!h-14"
        sx={{
          color: value ? '#1D1B20' : '#1D1B205D',
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: error ? '#B3261E !important' : '#79747E !important',
          },
        }}
        value={value as string}
        {...props}
      >
        {showSelected && (
          <MenuItem value="">
            {placeholder ? placeholder : `-- Select --`}
          </MenuItem>
        )}
        {data.map((option) => {
          return (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          );
        })}
      </MuiSelect>
      {error && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

const Select = ({ control, name, rules = {}, ...props }: TSelectProps) => {
  if (control && name && rules) {
    return (
      <Controller
        control={control}
        name={name}
        rules={rules}
        defaultValue={props.defaultValue}
        render={({ field: { ref, onChange, ...restField }, fieldState }) => {
          const value =
            restField.value !== undefined
              ? restField.value
              : props.defaultValue;
          return (
            <CustomSelect
              {...restField}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              {...props}
              value={value}
              onChange={onChange}
            />
          );
        }}
      />
    );
  }
  return <CustomSelect {...props} />;
};

export default Select;
