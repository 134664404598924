import { SuccessResponse, UserInfoRoot } from './../models/user.model';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { API_ROUTES } from '../ApiRoutes';
import instance, { GET, POST } from '../services/api';
import {
  UserCreatePayload,
  UserInfo,
  UserItemSearch,
  UserSearchResponse,
  UsersOverviewModel,
  UsersPagination,
} from '../models/user.model';
import { SignedPutUrl } from '../models/signed-put-rl.model';
import { buildApiPath } from 'utils';
import { IListRequest } from '@data/models/common.model';
import { InactiveReasonsReponse } from '@data/models/inactive-reasons';

export const getUsersOverview = async (): Promise<UsersOverviewModel> => {
  const data: AxiosResponse<UsersOverviewModel> = await instance
    .get(API_ROUTES.usersOverview)
    .catch((error) => {
      throw error;
    });

  return data.data;
};

export const getUsersSearch = async (
  query: string,
): Promise<UserItemSearch[]> => {
  const data: AxiosResponse<UserSearchResponse> = await instance
    .get(API_ROUTES.usersSearch + `?query=${query}`)
    .catch((error) => {
      throw error;
    });
  return data.data.users;
};

export const getUsersPagination = async (
  page: number,
  searchQuery?: string,
): Promise<UsersPagination> => {
  const data: AxiosResponse<UsersPagination> = await instance
    .get(
      API_ROUTES.adminUsers + `?page=${page}&searchQuery=${searchQuery || ''}`,
    )
    .catch((error) => {
      throw error;
    });

  return data.data;
};

export const getUserDetail = async (
  userId: string,
): Promise<{ user: UserInfo }> => {
  const data: AxiosResponse<{ user: UserInfo }> = await instance
    .get(API_ROUTES.adminUser(userId))
    .catch((error) => {
      throw error;
    });

  return data.data;
};

export const updateAdminUser = async (
  userId: string,
  payload: UserInfo,
): Promise<UserInfo> => {
  const data: AxiosResponse<UserInfo> = await instance
    .patch(API_ROUTES.adminUser(userId), payload)
    .catch((error) => {
      throw error;
    });

  return data.data;
};

export const createAdminUser = async (
  payload: UserCreatePayload,
): Promise<UserInfo> => {
  const data: AxiosResponse<UserInfoRoot> = await instance
    .post(API_ROUTES.adminUsers, payload)
    .catch((error) => {
      throw error;
    });

  return data.data?.user;
};

export const getSignedPutUrl = async (
  userId: string,
  contentType: string,
): Promise<SignedPutUrl> => {
  const data: AxiosResponse<SignedPutUrl> = await instance
    .get(API_ROUTES.adminUser(userId) + `/photo?contentType=${contentType}`)
    .catch((error) => {
      throw error;
    });
  return data.data;
};

export const uploadLogoCompanyToAWS = async (
  signedPutUrl: string,
  file: File,
) => {
  const config: AxiosRequestConfig = {
    method: 'put',
    url: signedPutUrl,
    headers: {
      'Content-Type': file.type,
    },
    data: file,
  };
  const data = await axios(config);

  return data.data;
};

export const deleteUser = async (userId: string) => {
  const data: AxiosResponse<any> = await instance
    .delete(API_ROUTES.deleteUser(userId))
    .catch((error) => {
      throw error;
    });

  return data.data;
};

export const getUserCheckIns = async (
  userId: string,
  params: IListRequest,
): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await GET(
      buildApiPath({
        path: API_ROUTES.getUserCheckIns(userId),
        params: params,
      }),
    );
    return response.data;
  } catch (e: unknown) {
    if (typeof e === 'string') {
      throw Error(e.toUpperCase());
    } else if (e instanceof Error) {
      throw Error(e.message);
    }
    throw Error('');
  }
};

export const exportUserCheckIns = async (userId: string): Promise<any> => {
  const data: AxiosResponse<any> = await instance
    .get(API_ROUTES.exportUserCheckIns(userId))
    .catch((error) => {
      throw error;
    });

  return data.data;
};

export const getConversationTag = async (): Promise<any> => {
  try {
    const data: AxiosResponse<SignedPutUrl> = await instance
      .get(API_ROUTES.getConversationTag)
      .catch((error) => {
        throw error;
      });
    return data.data;
  } catch (e: unknown) {
    if (typeof e === 'string') {
      throw Error(e.toUpperCase());
    } else if (e instanceof Error) {
      throw Error(e.message);
    }
    throw Error('');
  }
};

export const getInactiveReasons = async () => {
  try {
    const data: AxiosResponse<InactiveReasonsReponse> = await instance
      .get(API_ROUTES.getInactiveReasons)
      .catch((error) => {
        throw error;
      });
    return data.data;
  } catch (e: unknown) {
    if (typeof e === 'string') {
      throw Error(e.toUpperCase());
    } else if (e instanceof Error) {
      throw Error(e.message);
    }
    throw Error('');
  }
};

export const existPhone = async (phone: string, userId?: string | null) => {
  try {
    const data: AxiosResponse<SuccessResponse> = await POST(
      API_ROUTES.existPhone,
      {
        phone,
        userId,
      },
    );
    return data.data;
  } catch (e: unknown) {
    if (typeof e === 'string') {
      throw Error(e.toUpperCase());
    } else if (e instanceof Error) {
      throw Error(e.message);
    }
    throw Error('');
  }
};
