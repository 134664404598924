/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode } from 'react';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
} from '@mui/material';
import { Controller } from 'react-hook-form';

export interface ISelectOption {
  value: any;
  label: string;
}

type TSelectProps = MuiSelectProps & {
  data: ISelectOption[];
  control?: any;
  rules?: { [key: string]: string };
  name?: `${string}` | `${string}.${string}` | `${string}.${number}`;
  labelClassName?: string;
  placeholder?: string;
  error?: boolean;
  helperText?: ReactNode;
  showSelected?: boolean;
  fullWidth?: boolean;
};

const CustomSelect = ({
  data,
  label,
  labelClassName = '!bg-white !px-2 !-ml-2',
  error = false,
  helperText = '',
  value = '',
  showSelected = true,
  fullWidth = true,
  ...props
}: TSelectProps) => {
  return (
    <FormControl fullWidth={fullWidth} error={error}>
      <InputLabel
        shrink={true}
        className={labelClassName}
        required={props.required}
        sx={{
          '& .MuiFormLabel-asterisk': {
            color: '#DC362E',
          },
          color: props.disabled ? '#b5b5b5' : '',
          ': hover': {
            cursor: props.disabled ? 'not-allowed' : '',
          },
        }}
      >
        {label}
      </InputLabel>
      <MuiSelect
        displayEmpty
        className="!h-14"
        value={value as string}
        sx={{
          color: value ? '#1D1B20' : '#1D1B205D',
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: error ? '#B3261E !important' : '#79747E !important',
          },
          '& .MuiInputBase-input .MuiSelect-select': {
            padding: '16px',
            height: '26px',
          },
          '& .MuiSvgIcon-root': {
            color: '#213B54',
          },
        }}
        {...props}
        inputProps={{
          className: props.disabled ? '!cursor-not-allowed' : '',
        }}
      >
        {showSelected && <MenuItem value="">-- Select --</MenuItem>}

        {data?.map((option) => {
          return (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          );
        })}
      </MuiSelect>
      {error && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

const SelectV2 = ({ control, name, rules = {}, ...props }: TSelectProps) => {
  if (control && name && rules) {
    return (
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field: { ref, value, ...restField }, fieldState }) => {
          value = value ?? '';
          return (
            <CustomSelect
              value={value}
              {...restField}
              inputRef={ref}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              {...props}
            />
          );
        }}
      />
    );
  }
  return <CustomSelect {...props} />;
};

export default SelectV2;
