import React, { useState } from 'react';
import { HeaderPage } from '../../../components/HeaderPage/HeaderPage';
import { DASHBOARD_ROUTES } from '../../../Routes';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { CompanyInfo } from '../../../data/models/company.model';
import {
  getCompanyUsers,
  ICompanyUserListRequest,
} from '../../../data/api/company-employee.api';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { selectCompany } from '../../../store/companySlide';
import { Stack, Typography, useTheme } from '@mui/material';
import {
  GridColDef,
  GridEventListener,
  GridRenderCellParams,
} from '@mui/x-data-grid';
import Table from 'components/Table';
import { saveUser } from 'store/userSlide';
import { SearchTracking } from 'components/SearchTracking';
import ExportTraking from 'assets/icon-components/exportTracking';
import Button from 'components/Button';
import { useForm } from 'react-hook-form';
import MultiSelectTracking from 'components/MultiSelectTracking';
import DatePickerTracking from 'components/DatePickerTracking';
import TokenService from 'data/services/token.service';
import { AxiosResponse } from 'axios';
import { buildApiPath } from 'utils';
import instance from 'data/services/api';
import { API_ROUTES } from 'data/ApiRoutes';
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import tz from 'moment-timezone';
import CheckIcon from 'assets/icon-components/checkIcon';
import { getConversationTag } from 'data/api/user.api';
import { handleTag } from 'store/intercomSlice';
import { saveNavigateLinkHeader } from 'store/navigateSlide';

const FilterBox = ({ control, label, name, data, fullWidth }: any) => {
  return (
    <MultiSelectTracking
      name={name}
      label={label}
      control={control}
      data={data}
      size="small"
      fullWidth={fullWidth}
      variant="outlined"
      customSx={{
        form: { height: '32px !important' },
        label: {
          fontWeight: 'bold',
          fontSize: '14px',
          lineHeight: '20px',
        },
        select: {
          border: '1px solid #213B54',
          borderRadius: '10px',
          color: '#213B54',
        },
      }}
    />
  );
};

export const CompanyUsers = () => {
  const { company_id } = useParams();
  const companyInfo: CompanyInfo = useSelector(selectCompany);
  const theme = useTheme();
  const [companiesEmployeesRoot, setCompaniesEmployeesRoot] = useState<any>();
  const [tag, setTag] = React.useState<any[]>([]);
  const [query, setQuery] = React.useState<string>();
  const [filters, setFilters] = useState<any>();
  const navigate = useNavigate();
  const [filterTable, setFilterTable] = useState<ICompanyUserListRequest>({
    sortBy: 'created_date',
    order: undefined,
    page: 0,
    pageSize: 50,
  });

  const dispatch = useDispatch();
  const { handleSubmit, control, watch } = useForm({
    mode: 'onChange',
  });

  const fetchConversationTag = async () => {
    try {
      const data: any = await getConversationTag();
      setTag(data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchData = async (values?: any) => {
    try {
      if (company_id) {
        const data: any = Object.entries(values).map(([key, value]) => ({
          field: key,
          values: value,
        }));

        setFilters(data);
        const res: any = await getCompanyUsers(company_id, {
          ...filterTable,
          isActive: data[0]?.values,
          isAdmin: data[1]?.values,
          startDate:
            data[3]?.values && moment(data[3]?.values).isValid()
              ? moment(data[3]?.values).startOf('day').toString()
              : undefined,
          endDate:
            data[4]?.values && moment(data[4]?.values).isValid()
              ? moment(data[4]?.values).endOf('day').toString()
              : undefined,
          tags: data[2].values,
          query: query,
        });
        setCompaniesEmployeesRoot(res);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleExportCSV = (blob: any) => {
    const data = new Blob([blob], { type: 'text/csv' });
    const csvURL = window.URL.createObjectURL(data);
    const tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('download', companyInfo?.name + '_users.csv');
    tempLink.click();
  };

  const fetch = async (companyId?: string) => {
    try {
      const accessToken = TokenService.getToken()?.access_token;
      const data: AxiosResponse<any> = await instance.get(
        buildApiPath({
          path: API_ROUTES.companiesUsersExportCSV(companyId || ''),
          params:
            {
              ...filterTable,
              isActive: filters[0]?.values,
              isAdmin: filters[1]?.values,
              startDate:
                filters[3]?.values && moment(filters[3]?.values).isValid()
                  ? moment(filters[3]?.values).startOf('day').toString()
                  : undefined,
              endDate:
                filters[4]?.values && moment(filters[4]?.values).isValid()
                  ? moment(filters[4]?.values).endOf('day').toString()
                  : undefined,
              timezone: tz.tz.guess(),
              tags: filters[2].values,
              query: query,
            } || null,
        }),
        {
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
      handleExportCSV(data.data);
    } catch (error) {
      console.error('Error: ', error);
    }
  };

  const handleRowClick: GridEventListener<'rowClick'> = (params) => {
    dispatch(saveUser(params.row));
    window.location.href = `${DASHBOARD_ROUTES.USER_DETAIL_ID(
      params.row.userId,
    )}`;
  };

  React.useEffect(() => {
    fetchConversationTag();
  }, []);

  React.useEffect(() => {
    handleSubmit(fetchData)();
  }, [filterTable, query]);

  React.useEffect(() => {
    const subscription = watch(async () => await handleSubmit(fetchData)());
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch, query]);

  const newColumns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      minWidth: 200,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Typography
            sx={{
              fontSize: '12px',
            }}
          >
            {params.row.name}
          </Typography>
        );
      },
    },
    {
      field: 'currentEvent',
      headerName: 'Current Event',
      flex: 1,
      minWidth: 240,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Typography
            sx={{
              fontSize: '12px',
            }}
          >
            {params.row?.currentEvent || ''}
          </Typography>
        );
      },
    },
    {
      field: 'conversationContactsIntercomId',
      headerName: 'Link to Intercom',
      flex: 1,
      sortable: true,
      minWidth: 150,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <>
            {params.row.conversationContactsIntercomId && (
              <Link
                target="_blank"
                rel="noopener noreferrer"
                onClick={(event) => event.stopPropagation()}
                to={`${process.env.REACT_APP_LINK_TO_INTERCOM_URL}${params.row?.conversationContactsIntercomId}/all-conversations`}
                style={{
                  textDecorationColor: '#213B54',
                }}
              >
                <Stack
                  direction={'row'}
                  gap={0.5}
                  sx={{
                    color: '#213B54',
                  }}
                >
                  Open
                  <OpenInNewRoundedIcon
                    sx={{
                      height: '16px',
                      width: '16px',
                    }}
                  />
                </Stack>
              </Link>
            )}
          </>
        );
      },
    },
    {
      field: 'tags',
      headerName: 'Tags',
      flex: 1,
      minWidth: 280,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Stack width={'100%'} flexWrap={'wrap'} direction={'row'}>
            {params.row.tags &&
              params.row.tags.length > 0 &&
              params.row.tags.map((item: any) => {
                return (
                  <div
                    key={item}
                    className="!my-[4px]"
                    style={{
                      marginRight: '4px',
                    }}
                    onClick={(event) => event.stopPropagation()}
                  >
                    <Typography
                      sx={{
                        color: '#356766',
                        backgroundColor: '#3567661A',
                        border: '1px solid #356766',
                        borderRadius: '20px',
                        paddingX: '8px',
                        height: '24px',
                        fontSize: '14px',
                        fontWeight: 500,
                        width: 'fit-content',
                        display: 'block',
                      }}
                      onClick={() => {
                        dispatch(saveUser(params.row));
                        dispatch(handleTag(item));
                        const fromLink =
                          DASHBOARD_ROUTES.COMPANY_ID_EMPLOYEES(company_id);
                        const toLink = DASHBOARD_ROUTES.USER_ID_CONVERSATION(
                          params.row.userId,
                        );
                        dispatch(saveNavigateLinkHeader({ fromLink, toLink }));
                        navigate(toLink);
                      }}
                      component="span"
                    >
                      {item}
                    </Typography>
                  </div>
                );
              })}
          </Stack>
        );
      },
    },
    {
      field: 'created_date',
      headerName: 'Date Added',
      flex: 1,
      minWidth: 160,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        return moment
          .utc(params.row.createdDate)
          .local()
          .format('MM/DD/YYYY, hh:mm A');
      },
    },
    {
      field: 'lastLoginDate',
      headerName: 'Last Login',
      flex: 1,
      minWidth: 160,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        return params.row.lastLoginDate
          ? moment
              .utc(params.row.lastLoginDate)
              .local()
              .format('MM/DD/YYYY, hh:mm A')
          : '';
      },
    },
    {
      field: 'loginCount',
      headerName: 'Total Logins',
      flex: 1,
      minWidth: 100,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Typography
            sx={{
              fontSize: '12px',
            }}
          >
            {params.row.loginCount}
          </Typography>
        );
      },
    },
    {
      field: 'is_active',
      headerName: 'Active',
      align: 'center',
      headerAlign: 'center',
      sortable: true,
      flex: 1,
      minWidth: 100,
      renderCell: (params: GridRenderCellParams) => {
        return <>{params.row.active && <CheckIcon />}</>;
      },
    },
    {
      field: 'is_admin',
      headerName: 'Admin',
      align: 'center',
      headerAlign: 'center',
      sortable: true,
      flex: 1,
      minWidth: 100,
      renderCell: (params: GridRenderCellParams) => {
        return <>{params.row.isAdmin && <CheckIcon />}</>;
      },
    },
  ];

  return (
    <>
      <HeaderPage
        title={{
          title: companyInfo?.name ? companyInfo?.name : '',
          hasBack: true,
        }}
        tabs={[
          {
            title: 'Overview',
            selected: false,
            link: DASHBOARD_ROUTES.COMPANY_DETAIL + company_id,
          },
          {
            title: 'Users',
            selected: true,
            link: DASHBOARD_ROUTES.COMPANY_ID_EMPLOYEES(company_id),
          },
          {
            title: 'Escalation Protocol',
            selected: false,
            link: DASHBOARD_ROUTES.COMPANY_ID_ESCALATION(company_id),
          },
          {
            title: 'Company Notes',
            selected: false,
            link: DASHBOARD_ROUTES.COMPANY_ID_NOTES(company_id),
          },
          {
            title: 'Usage Logs',
            selected: false,
            link: DASHBOARD_ROUTES.COMPANY_ID_TRACKING(company_id),
          },
        ]}
      />

      <div className="nv-container">
        <div className="section employees mb-6">
          <div className="tabs bg-[#FDFAF7] w-[345px]">
            <Stack
              direction={'row'}
              justifyContent={'space-around'}
              height={'49px'}
              alignItems={'center'}
            >
              <Link
                to={DASHBOARD_ROUTES.COMPANY_ID_EMPLOYEES(company_id)}
                className="tab"
                style={{
                  color: '#213B54',
                  height: '100%',
                  alignItems: 'center',
                  borderBottom: '3px solid #213B54',
                  textAlign: 'center',
                  display: 'flex',
                  fontSize: '13px',
                  textDecoration: 'none',
                  fontWeight: 700,
                }}
              >
                Users
              </Link>
              <Link
                to={DASHBOARD_ROUTES.COMPANY_EMPLOYEES_ADD(company_id)}
                className="tab"
                style={{
                  color: '#213B54',
                  fontSize: '13px',
                  fontWeight: 700,
                  textDecoration: 'none',
                }}
              >
                Add a User
              </Link>
              <Link
                to={DASHBOARD_ROUTES.COMPANY_EMPLOYEES_IMPORT(company_id)}
                className="tab "
                style={{
                  color: '#213B54',
                  fontSize: '13px',
                  fontWeight: 700,
                  textDecoration: 'none',
                }}
              >
                Import Users
              </Link>
            </Stack>
          </div>
          <hr />

          <Stack sx={{ marginTop: '10px' }} spacing={2} width={'100%'}>
            <Stack
              sx={{
                overflowX: 'auto',
                overflowY: 'hidden',
                [theme.breakpoints.up(1480)]: {
                  flexDirection: 'row',
                  alignItems: 'center',
                },
                [theme.breakpoints.down(1480)]: {
                  flexDirection: 'column',
                  alignItems: 'start',
                },
              }}
              paddingBottom={'8px'}
              width={'100%'}
            >
              <Stack
                direction={'row'}
                spacing={2}
                alignItems={'center'}
                width={'100%'}
              >
                <SearchTracking
                  label="Search for a user"
                  onChange={(title: string) => {
                    setQuery(title);
                  }}
                  debounce={800}
                />
                <Stack spacing={2} direction={{ xs: 'column', xxl: 'row' }}>
                  <Stack direction={'row'} spacing={2}>
                    <FilterBox
                      name="IsActive"
                      label="Is Active"
                      control={control}
                      data={[
                        {
                          value: 'Yes',
                          label: 'Yes',
                        },
                        {
                          value: 'No',
                          label: 'No',
                        },
                      ]}
                    />
                    <FilterBox
                      name="IsAdmin"
                      label="Is Admin"
                      control={control}
                      data={[
                        {
                          value: 'Yes',
                          label: 'Yes',
                        },
                        {
                          value: 'No',
                          label: 'No',
                        },
                      ]}
                    />
                    <FilterBox
                      name="tags"
                      label="Tags"
                      control={control}
                      data={tag.map((item) => {
                        return {
                          value: item.name,
                          label: item.name,
                        };
                      })}
                    />
                  </Stack>
                  <Stack direction={'row'} spacing={2}>
                    <DatePickerTracking
                      name="startLastLoginDate"
                      label="FROM Last Login Date"
                      control={control}
                      inputProps="!text-sm !h-[32px] !rounded-[10px]"
                    />
                    <DatePickerTracking
                      name="endLastLoginDate"
                      label="TO Last Login Date"
                      control={control}
                      inputProps="!text-sm !h-[32px] !rounded-[10px]"
                    />
                  </Stack>
                </Stack>
              </Stack>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: '#213B54',
                  color: '#ffffff',
                  fontWeight: 700,
                  height: '40px',
                  width: '145px',
                  [theme.breakpoints.down(1480)]: {
                    marginTop: '12px',
                  },
                  [theme.breakpoints.up(1480)]: {
                    marginLeft: '16px',
                  },
                }}
                startIcon={
                  <ExportTraking
                    style={{
                      fontWeight: 700,
                    }}
                  />
                }
                onClick={() => {
                  if (company_id) {
                    fetch(company_id);
                  }
                }}
              >
                Export.csv
              </Button>
            </Stack>
            <Table
              getRowId={(r) => r.userId}
              loading={status === 'loading'}
              rows={
                companiesEmployeesRoot && companiesEmployeesRoot.users
                  ? companiesEmployeesRoot.users
                  : []
              }
              getRowHeight={() => 'auto'}
              columns={newColumns}
              paginationMode="server"
              sortingMode="server"
              rowCount={parseInt(
                `${companiesEmployeesRoot?.pager?.total || '0'}`,
              )}
              disableColumnFilter={true}
              disableColumnMenu={true}
              filter={filterTable}
              onFilterChange={setFilterTable}
              pageSizeOptions={[10, 25, 50, 100]}
              onRowClick={handleRowClick}
              sx={{
                '& .MuiDataGrid-columnHeaders': {
                  outline: 'none !important',
                  backgroundColor: '#F8F9FB',
                  maxHeight: '47px !important',
                  minHeight: '47px !important',
                  borderWidth: '1px 0px 1px 0px',
                  borderStyle: 'solid',
                  borderColor: '#DBD8D8',
                },
                '& .MuiDataGrid-columnHeaderTitle': {
                  color: '#213B54',
                  fontWeight: 700,
                  fontSize: '13px',
                  lineHeight: '20px',
                  letterSpacing: '0.1px',
                },
                '& .MuiDataGrid-cell': {
                  color: '#000000',
                  fontWeight: 500,
                  fontSize: '12px !important',
                  cursor: 'pointer',
                  padding: '8px !important',
                },
                '& .MuiTablePagination-select': {
                  fontWeight: 500,
                },
                '& .MuiTablePagination-selectLabel': {
                  fontWeight: 500,
                },
                '& .MuiTablePagination-displayedRows': {
                  fontWeight: 500,
                },
                '& .MuiDataGrid-virtualScroller': {
                  overflowY: 'hidden',
                },
              }}
            />
          </Stack>
        </div>
      </div>
    </>
  );
};
