import React from 'react';
import { Link } from 'react-router-dom';
import { Tab } from '../../interface/tab.interface';
import { useAppDispatch } from 'store/hooks';
import { handleTag } from 'store/intercomSlice';

interface Props {
  tabs: Tab[];
}
export const HeaderTabs: React.FC<Props> = ({ tabs }) => {
  const dispatch = useAppDispatch();
  const renderTabList = () => {
    return tabs.map((tab) => {
      if (tab.link) {
        return (
          <React.Fragment key={tab.title}>
            <Link
              to={tab.link}
              className={
                'tab float-left cursor-pointer text-[#333] font-bold no-underline mr-[40px] ' +
                renderClassSelected(tab.selected)
              }
              style={{
                color: '#213B54',
                fontSize: '13px',
                fontWeight: 700,
              }}
              onClick={() => {
                dispatch(handleTag(''));
              }}
            >
              {tab.title}
            </Link>
          </React.Fragment>
        );
      }
      return <React.Fragment key={tab.title} />;
    });
  };

  const renderClassSelected = (selected: boolean | undefined) => {
    return selected ? 'selected' : '';
  };

  return <div className="tabs overflow-auto !ml-[30px]">{renderTabList()}</div>;
};
