import { Avatar, Box, Stack, Typography } from '@mui/material';
import React from 'react';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';

export const ContentParser = ({ value }: { value: string }) => {
  return (
    <Box
      sx={{
        a: {
          color: 'inherit',
        },
      }}
    >
      {parse(DOMPurify.sanitize(value))}
    </Box>
  );
};

interface IMessageFromProps {
  body: any;
  time: string;
  avatar: any;
  authorName: string;
  tags?: string[];
  conversationId: string;
  conversationPartIdRef: string;
  tagCurrent?: string;
}

export const MessageFrom = ({
  body,
  time,
  avatar,
  authorName,
  tags,
  conversationId,
  conversationPartIdRef,
  tagCurrent,
}: IMessageFromProps) => {
  const refRelative = React.useRef<any>();
  const refAbsolute = React.useRef<any>();
  const [width, setWidth] = React.useState();

  React.useEffect(() => {
    if (refRelative.current.scrollWidth < refAbsolute.current?.scrollWidth) {
      setWidth(refAbsolute.current?.scrollWidth);
    } else {
      setWidth(refRelative.current?.scrollWidth);
    }
  }, []);

  return (
    <Stack
      direction={'row'}
      spacing={1}
      alignItems={'end'}
      padding={'8px'}
      // marginTop={conversationId === conversationPartIdRef ? '16px' : '0px'}
    >
      {avatar ? (
        <img
          src={avatar}
          className="h-[23px] w-[23px]"
          style={{
            borderRadius: '50%',
          }}
        />
      ) : (
        <Avatar
          sx={{
            color: 'white',
            fontSize: '13px',
            backgroundColor: '#213B54',
            fontWeight: 600,
          }}
          className="!h-[23px] !w-[23px]"
        >
          {authorName?.trim()?.charAt(0) || ''}
        </Avatar>
      )}
      <Stack
        sx={{
          backgroundColor: '#FAF7F4',
          padding: '12px',
          width: width,
          textAlign: 'right',
          maxWidth: '80%',
          borderRadius: '15px',
          boxShadow: '0px 2px 8px 0px #00000026',
          position: 'relative',
          zIndex: 1,
        }}
        spacing={1}
        ref={refRelative}
      >
        <Stack
          sx={{
            width: 'max-content',
            position: 'absolute',
            top: '-16px',
            left: '0px',
            zIndex: 2,
            minWidth: '160px',
          }}
          direction={'row'}
          ref={refAbsolute}
        >
          {tags &&
            tags.length > 0 &&
            tags.map((tag) => {
              return (
                <>
                  <Typography
                    sx={{
                      color: '#356766',
                      backgroundColor:
                        conversationId === conversationPartIdRef &&
                        tagCurrent === tag
                          ? 'rgba(239, 207, 81, 0.44)'
                          : '#EAEFEF',
                      border: '1px solid #356766',
                      borderRadius: '20px',
                      paddingX: '8px',
                      height: '24px',
                      fontSize: '14px',
                      fontWeight: 500,
                      marginRight: '4px',
                      boxShadow: '2px 2px rgba(0, 0, 0, 0.25)',
                    }}
                  >
                    {tag}
                  </Typography>
                </>
              );
            })}
        </Stack>
        <Typography
          sx={{
            lineHeight: '18px',
            textAlign: 'left',
            fontSize: '14px',
            fontWeight: 400,
            color: '#000000',
            wordWrap: 'break-word',
          }}
        >
          <ContentParser value={body || ''} />
        </Typography>
        <Typography
          sx={{
            textAlign: 'start',
            fontSize: '12px',
            fontWeight: 500,
            color: '#7D818C',
            width: '100%',
          }}
        >
          {time}
        </Typography>
      </Stack>
    </Stack>
  );
};
