/* eslint-disable @typescript-eslint/no-empty-function */
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React, { ReactNode } from 'react';

type TModalCustomProps = {
  modal: boolean;
  groupButton?: ReactNode;
  handleClose: () => void;
  title?: ReactNode;
  content?: ReactNode;
};

const ModalCustom = ({
  title,
  content,
  modal,
  groupButton,
  handleClose = () => {},
}: TModalCustomProps) => {
  return (
    <Dialog
      open={modal}
      onClose={handleClose}
      PaperProps={{ sx: { borderRadius: 7, minHeight: '200px' } }}
      sx={{
        overflow: { xs: 'auto', md: 'hidden' },
        '& .MuiPaper-root': {
          maxWidth: 'none',
          margin: '24px',
        },
      }}
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{
          fontWeight: 700,
          fontSize: '21px',
          lineHeight: '26,64px',
          color: '#213B54',
        }}
      >
        {title}
      </DialogTitle>
      <DialogContent
        sx={{
          padding: 0,
          width: 'auto',
          overflow: { xs: 'auto', md: 'hidden' },
          height: 'auto',
        }}
      >
        {content}
      </DialogContent>
      <DialogActions sx={{ padding: 0, marginTop: 3 }}>
        {groupButton}
      </DialogActions>
    </Dialog>
  );
};

export default ModalCustom;
