/* eslint-disable @typescript-eslint/no-explicit-any */

import React from 'react';
import { Controller } from 'react-hook-form';
import { PatternFormat, PatternFormatProps } from 'react-number-format';
import { TTextFieldProps } from '@components/TextFieldNumber';

import {
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
} from '@mui/material';

// eslint-disable-next-line react/display-name
const TextFieldWithMask = React.forwardRef<
  HTMLInputElement,
  PatternFormatProps<any>
>(
  ({
    format = '###-##-####',
    mask = '_',
    control,
    name,
    rules = {},
    ...restProps
  }) => {
    if (control && name && rules) {
      rules = {
        validate: (value: any) => {
          const regex = /^\d{1,10}$/;
          if (!value) return true;
          if (
            !value.replaceAll(/\D/g, '')?.trim() ||
            value.replaceAll(/\D/g, '')?.trim()?.length < 10 ||
            regex.test(value.replaceAll(/\D/g, '')?.trim()) === false
          )
            return 'Please enter a valid phone number with exactly 10 digits, without any spaces or special characters.';
          return true;
        },
        ...rules,
      };
      return (
        <Controller
          control={control}
          name={name}
          rules={rules}
          render={({ field, fieldState }) => {
            return (
              <PatternFormat
                {...field}
                getInputRef={field.ref}
                customInput={TextField}
                format={format}
                mask={mask}
                error={!!fieldState.error}
                helperText={fieldState?.error?.message || ''}
                {...restProps}
              />
            );
          }}
        />
      );
    }

    return (
      <PatternFormat
        customInput={TextField}
        format={format}
        mask={mask}
        {...restProps}
      />
    );
  },
);

const CustomTextField = (props: TTextFieldProps) => {
  return (
    <MuiTextField
      fullWidth
      placeholder={
        props.placeholder ||
        (props.label ? `Type ${props.label}` : 'Type here...')
      }
      InputLabelProps={{
        shrink: true,
        className: `!pr-1.5 ${props.disabled ? '' : 'bg-white'}`,
        sx: {
          '&.Mui-error': {
            color: '#B3261E !important',
          },
        },
      }}
      SelectProps={{
        className: `${!props.value ? '!text-onSurface/40' : '!text-onSurface'}`,
      }}
      InputProps={{
        className: `${props.select ? '' : '!text-onSurface'}`,
        sx: {
          '&.Mui-error': {
            color: '#B3261E !important',
          },
        },
      }}
      inputProps={{
        ...props.inputProps,
        className: `!py-3 md:!py-4 !leading-6 !h-auto  ${
          props.type === 'email' ? ' lowercase' : ''
        }`,
        sx: {
          '::placeholder':
            props.type === 'email' ? { textTransform: 'none' } : {},
        },
      }}
      sx={{
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: props.disabled
            ? '#EFEEF0 !important'
            : '#79747E !important',
        },
        '& .Mui-error .MuiOutlinedInput-notchedOutline': {
          borderColor: '#B3261E !important',
        },
        '& .MuiFormLabel-asterisk': {
          color: 'red',
        },
      }}
      {...props}
    />
  );
};

const TextField = React.forwardRef<HTMLInputElement, TTextFieldProps>(
  (props, ref) => {
    return <CustomTextField {...props} inputRef={ref} />;
  },
);

export default TextFieldWithMask;
