import React, { useEffect, useState } from 'react';
import { HeaderPage } from '../../../components/HeaderPage/HeaderPage';
import { DASHBOARD_ROUTES } from '../../../Routes';
import { Link, useParams } from 'react-router-dom';
import { getCompanyNotes } from '../../../data/api/company-note.api';
import { NoteInfo } from '../../../data/models/company-note.model';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { selectCompany } from 'store/companySlide';
import { CompanyInfo } from '../../../data/models/company.model';

export const CompanyNotes = () => {
  const { company_id } = useParams();
  const companyInfo: CompanyInfo = useSelector(selectCompany);

  const [companyNotesInfo, setCompanyNotesInfo] = useState<NoteInfo[]>([]);
  const [includeAuto, setIncludeAuto] = useState<boolean>(true);

  useEffect(() => {
    if (company_id) {
      void fetchCompanyNotes(company_id);
    }
  }, [includeAuto]);

  const fetchCompanyNotes = async (companyId: string) => {
    await getCompanyNotes(companyId, includeAuto)
      .then((resp) => {
        setCompanyNotesInfo(resp.notes);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const renderCheckIcon = () => {
    return { __html: '&check;' };
  };
  return (
    <>
      <HeaderPage
        title={{
          title: companyInfo?.name ? companyInfo?.name : '',
          hasBack: true,
        }}
        tabs={[
          {
            title: 'Overview',
            selected: false,
            link: DASHBOARD_ROUTES.COMPANY_DETAIL + company_id,
          },
          {
            title: 'Users',
            selected: false,
            link: DASHBOARD_ROUTES.COMPANY_ID_EMPLOYEES(company_id),
          },
          {
            title: 'Escalation Protocol',
            selected: false,
            link: DASHBOARD_ROUTES.COMPANY_ID_ESCALATION(company_id),
          },
          {
            title: 'Company Notes',
            selected: true,
            link: DASHBOARD_ROUTES.COMPANY_ID_NOTES(company_id),
          },
          {
            title: 'Usage Logs',
            selected: false,
            link: DASHBOARD_ROUTES.COMPANY_ID_TRACKING(company_id),
          },
        ]}
      />
      <div className="nv-container">
        <div className="section notes">
          <div className="tabs">
            <Link
              to={DASHBOARD_ROUTES.COMPANY_ID_NOTES(company_id)}
              className="tab selected"
            >
              Internal Notes
            </Link>
            <Link
              to={DASHBOARD_ROUTES.COMPANY_ID_NOTES_NEW(company_id)}
              className="tab"
            >
              Add Note
            </Link>
          </div>
          <hr />

          <div className="filter">
            <input
              id="checkbox-include-notes"
              type="checkbox"
              defaultChecked={includeAuto}
              onChange={() => setIncludeAuto(!includeAuto)}
            />
            <label htmlFor="checkbox-include-notes" className="cursor-pointer">
              Include notes that were created automatically
            </label>
          </div>

          <div className="table">
            <div className="row header">
              <div className="column auto">Auto</div>
              <div className="column note">Note</div>
              <div className="column user">Created by</div>
              <div className="column created-note">Date</div>
            </div>

            {companyNotesInfo.map((companyNote, index) => {
              return (
                <div className="row" key={index}>
                  {companyNote.isAutoGenerated ? (
                    <div
                      className="column auto"
                      dangerouslySetInnerHTML={renderCheckIcon()}
                    />
                  ) : (
                    <div className="column auto">&nbsp;</div>
                  )}
                  <div className="column note">{companyNote.note}</div>
                  <div className="column user">
                    {companyNote.createdByUsername}
                  </div>
                  <div className="column created-note">
                    {moment(companyNote.createdDate).format('LL')}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};
