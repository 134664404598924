import React from 'react';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const WYSIWYGEditor = ({
  onChange,
  value,
  placeholder,
  editorStyle,
  wrapperClassName,
}: {
  onChange: any;
  value: any;
  placeholder?: string;
  editorStyle?: any;
  wrapperClassName?: any;
}) => {
  const [editorState, setEditorState] = React.useState(
    EditorState.createEmpty(),
  );
  const [updated, setUpdated] = React.useState(false);

  React.useEffect(() => {
    if (!updated) {
      const defaultValue = value ? value : '';
      const blocksFromHtml = htmlToDraft(defaultValue);
      const contentState = ContentState.createFromBlockArray(
        blocksFromHtml.contentBlocks,
        blocksFromHtml.entityMap,
      );
      const newEditorState = EditorState.createWithContent(contentState);
      setEditorState(newEditorState);
    }
  }, [value]);

  const onEditorStateChange = (editorState: any) => {
    setUpdated(true);
    setEditorState(editorState);

    return onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  return (
    <>
      <div className="editor">
        <Editor
          spellCheck
          editorState={editorState}
          placeholder={placeholder}
          onEditorStateChange={onEditorStateChange}
          editorStyle={editorStyle}
          wrapperClassName={wrapperClassName}
          handlePastedText={() => false}
          toolbar={{
            options: [
              'inline',
              'blockType',
              'fontSize',
              'list',
              'textAlign',
              'colorPicker',
              'link',
              'embedded',
              'emoji',
              'image',
              'remove',
              'history',
            ],
          }}
        />
      </div>
    </>
  );
};

export default WYSIWYGEditor;
